const { getValueOrDefault } = require('@k_frontend/core')

const { formatProductFriendlyName } = require('../utils/friendlyName')

const self = {}

self.offersListingProductsToCatalog = (products) => {
  const getOfferIsPrime = (product) => {
    return (
      Number(product.primePriceWithDiscount) > 0 &&
      Number(product.primePriceWithDiscount) < Number(product.priceWithDiscount)
    )
  }
  return (
    products?.map((product) => ({
      available: product.available,
      code: product.code,
      name: product.name,
      friendlyName: formatProductFriendlyName(product.name),
      manufacturer: { name: product.manufacturer },
      image: product.image,
      offerDiscount: product.offerDiscount,
      quantity: product.quantity,
      rating: product.rating,
      ratingCount: product.ratingCount,
      primePrice: product.primePrice,
      oldPrice: product.oldPrice,
      oldPrimePrice: product.oldPrimePrice,
      maxInstallment: product?.maxInstallment,
      sellerName: product.seller_name || 'KaBuM!',
      sellerId: product.seller_id || 0,
      category: product.category,
      price: product.priceWithDiscount || 0,
      flags: {
        isFlash: product?.flags?.isFlash,
        isOpenbox: product.flags.openbox,
        isPrime: getOfferIsPrime(product),
        isOffer: true,
        isPreOrder: getValueOrDefault(product?.flags?.isPreOrder, false),
        isHighlight: getValueOrDefault(product.isHighlight, false),
        isFreeShipping: getValueOrDefault(product?.flags?.freeShipping, false)
      },
      offer: {
        name: '',
        code: product.offerCode,
        endsAt: product.offerDates.endDate,
        startsAt: product.offerDates.startDate,
        discountPercentage: product.offerDiscount,
        quantityAvailable: product.quantity,
        hashCode: product.hashCode,
        priceWithDiscount: product.priceWithDiscount,
        mainColor: product?.colors?.main,
        textColor: product?.colors?.text
      },
      prime: getOfferIsPrime(product) && {
        price: product.primePrice,
        priceWithDiscount: product.primePriceWithDiscount,
        discountPercentage:
          product.offerDiscountPrime > 0
            ? product.offerDiscountPrime
            : product.offerDiscount,
        maxInstallmentPrime: product?.maxInstallmentPrime
      },
      tagCode: product.tagCode || 0,
      offerIdMarketplace: product.offerIdMarketplace,
      stamps: product.stamp
    })) || []
  )
}

self.offersHomeProductsToCatalog = (products, offer) => {
  const getOfferIsPrime = (product) => {
    return (
      Number(product.primePriceWithDiscount) > 0 &&
      Number(product.primePriceWithDiscount) < Number(product.priceWithDiscount)
    )
  }
  return (
    products?.map((product) => ({
      available: product.available,
      code: product.code,
      name: product.name,
      friendlyName: formatProductFriendlyName(product.name),
      manufacturer: { name: product.manufacturer },
      image: product.image,
      images: [product.image],
      offerDiscount: product.offerDiscount,
      offerIdMarketplace: product.offerIdMarketplace,
      quantity: product.quantity,
      rating: product.rating,
      ratingCount: product.ratingCount,
      primePrice: product.primePrice,
      oldPrice: product.oldPrice,
      oldPrimePrice: product.oldPrimePrice,
      maxInstallment: product?.maxInstallment,
      sellerName: product.sellerName || 'KaBuM!',
      sellerId: product.sellerId || 0,
      category: product.menu || '',
      price: product.priceWithDiscount || 0,
      priceWithDiscount: product.priceWithDiscount || 0,
      stamps: product.stamp,
      list: `oferta-${offer.path}`,
      flags: {
        isFlash: false,
        isOpenbox: product.flags.openbox,
        isFreeShipping: getValueOrDefault(product.flags?.freeShipping, false),
        isFreeShippingPrime: getValueOrDefault(
          product.flags?.freeShippingPrime,
          false
        ),
        isPrime: getOfferIsPrime(product),
        isOffer: true,
        isPreOrder: getValueOrDefault(product?.flags?.isPreOrder, false),
        isHighlight: getValueOrDefault(product.isHighlight, false)
      },
      offer: offer && {
        name: offer.name,
        endsAt: product.offerDates.endDate,
        startsAt: product.offerDates.startDate,
        discountPercentage: product.offerDiscount,
        quantityAvailable: product.quantity,
        hashCode: product.hash_code || product.hashCode,
        priceWithDiscount: product.priceWithDiscount,
        mainColor: product?.colors?.main,
        textColor: product?.colors?.text
      },
      prime: getOfferIsPrime(product) && {
        price: product.primePrice,
        priceWithDiscount: product.primePriceWithDiscount,
        discountPercentage: product.offerDiscount,
        maxInstallmentPrime: product?.maxInstallmentPrime
      },
      tagCode: product.tagCode || 0
    })) || []
  )
}

self.offerBannerResponse = (data) => ({
  id: data.banner.id,
  image: data.banner.imagem,
  description: data.descricao,
  offerDescriptionImage: data.banner.banner_descricao
})

self.offerBannerErrorResponse = () => ({
  id: 0,
  image: '',
  description: '',
  offerDescriptionImage: ''
})

module.exports = self
